import React from "react";

import { Typography } from "@mui/material";

import config from "../../configs/globalConfigs";

type Props = {
    text: string,
    font?: string,
    className?: string,
    html?: boolean,
};

const Text = ({ text, font, className, html = false }: Props) => (
    <Typography variant="body1" className={`${config.debug ? 'border-2 border-yellow-400' : ''} normal-case ${font ? `font-${font}` : ''} ${className}`}>
        {html ?
            <span className={`normal-case ${font ? `font-${font}` : ''} ${className}`} dangerouslySetInnerHTML={{ __html: text }}></span>
        : 
            text
        }
    </Typography>
);

export default Text;