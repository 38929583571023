import React from "react";

import { Container, Grid } from "@mui/material";

import HeroSection from "../../components/elements/HeroSection";
import ContactSection from "../../components/elements/ContactSection";
import Slider from "../../components/elements/Slider";
import InvestmentSection from "./Components/InvestmentSection";

import { Text } from "../../components/texts";

import useMeta from "../../components/useMeta";

const sliderData = [
  {
    image: '/images/Slider/slider-1.jpg',
    title: {
      text: 'EcoVision Tower',
      className: 'font-donegol text-primary-yellow text-start',
    },
    subtitle: {
      text: 'Nieuwbouwproject',
      className: 'text-secondary-grey',
    },
    texts: [
      "Met trots kondigt FR Real Estate de opening aan van EcoVision Plaza, een vooruitstrevend project dat moderne architectuur en duurzame innovatie samenbrengt. Gelegen in een bruisend deel van de stad, blinkt deze imposante toren uit in milieuvriendelijkheid en energie-efficiëntie. Met geavanceerde zonne-energiepanelen en een state-of-the-art regenwateropvangsysteem behoort EcoVision Plaza tot de meest energiezuinige gebouwen in de regio. Hoogrendementsglas optimaliseert natuurlijke lichtinval en temperatuurregeling, waardoor energiekosten worden verlaagd.",
      "Ontdek het ultramoderne interieur van GreenSky Tower, met flexibele kantoorruimtes voor maximale productiviteit en comfort. Elk aspect, van LED-verlichting tot energiezuinige verwarming en koeling, minimaliseert de ecologische voetafdruk. FR Real Estate introduceert EcoVision Plaza: een toekomstbestendige vastgoedoplossing die de normen van vandaag overstijgt en inspeelt op duurzaamheidseisen van morgen."
    ],
  },
  {
    image: '/images/Slider/slider-2.jpg',
    title: {
      text: 'The Metropolitan Lofts',
      className: 'font-donegol text-primary-yellow text-start',
    },
    subtitle: {
      text: 'Nieuwbouwproject',
      className: 'text-secondary-grey',
    },
    texts: [
      "FR Real Estate introduceert met trots 'The Metropolitan Lofts', een collectie van moderne lofts die stedelijke elegantie en comfort herdefiniëren. Gelegen in het dynamische stadscentrum, combineren deze lofts het beste van stedelijk wonen met gemakkelijke toegang tot culturele hotspots en zakelijke districten. Elk loft is ontworpen met een industriële flair, gebruikmakend van materialen als staal en beton, naast warme houttinten en grote ramen die zorgen voor een licht, open gevoel en prachtige uitzichten.",
      "Deze lofts zijn meer dan alleen woonruimtes; ze vormen een levendige gemeenschap met luxe voorzieningen zoals een lounge, werkplekken en een terras, allemaal ontworpen om het dagelijks leven te verrijken. 'The Metropolitan Lofts' staan voor een nieuwe norm in stedelijk wonen, waar luxe, functionaliteit en gemeenschapszin samenkomen."
    ],
  },
  {
    image: '/images/Slider/slider-3.jpg',
    title: {
      text: 'GreenSky Tower',
      className: 'font-donegol text-primary-yellow text-start',
    },
    subtitle: {
      text: 'Ons Droomproject',
      className: 'text-secondary-grey',
    },
    texts: [
      "In het bruisende hart van de stad verrijst een indrukwekkend symbool van vooruitgang en duurzaamheid - een innovatieve nieuwbouwtoren die niet alleen de skyline transformeert, maar ook de normen voor energie-efficiëntie radicaal herschrijft. Deze opvallende architectonische creatie, een meesterwerk van hedendaags design en technologische innovatie, vormt het trotse resultaat van een visionaire investeerder die zich onwrikbaar inzet voor het creëren van een betere toekomst voor komende generaties.",
      "Deze toren, met zijn slanke silhouet dat naar de hemel reikt, is meer dan alleen een architectonisch wonder. Het is een voorbeeld van toonaangevende technologieën en baanbrekende concepten op het gebied van energie-efficiëntie. Vanaf het allereerste ontwerp tot aan de voltooiing heeft elk aspect van deze structuur een compromisloze focus gehad op het minimaliseren van energieverbruik en het maximaliseren van duurzaamheid.",
    ],
  }
];

const HomePage = () => {
  return (
    useMeta({
      title: 'FR Real Estate - Expert in Duurzame Vastgoedinvesteringen',
      description: 'Ontdek de expertise van FR Real Estate in duurzame vastgoedinvesteringen. Van energiezuinige nieuwbouw tot strategisch gelegen kantoorgebouwen, wij combineren financieel rendement met maatschappelijke verantwoordelijkheid voor een vruchtbare samenwerking. Neem vandaag nog contact op met ons team.',
      keywords: 'Duurzame vastgoedinvesteringen, Energiezuinige nieuwbouw, Kantoorgebouwen, Distributiecentra, Industriegronden, Winkelcentra, Maatschappelijke verantwoordelijkheid, Financieel rendement, Vastgoedprojecten, Toekomstgericht',
    }),
    <React.Fragment>
      {/* Hero */}
      <HeroSection />

      {/* About */}
      <section id="over" className="py-32">
        <Container>
          <Grid container columns={6}>
            <Grid item xs={2} sm={2} md={2} lg={1} className="hidden sm:flex">
              <img className="m-auto mr-0 max-w-full max-h-full" src="/images/over-text.svg" alt="About" />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={2} className="px-5">
              <Text text="Welkom bij FR Real Estate, dé expert in duurzame vastgoedinvesteringen. Wij richten ons op het selecteren van vooruitstrevende vastgoedprojecten zoals energiezuinige nieuwbouw, strategisch gelegen kantoorgebouwen, essentiële distributiecentra, industriegronden en dynamische winkelcentra." className="text-sm text-secondary-grey" />
              <Text text="Bij FR Real Estate combineren we financieel rendement met maatschappelijke verantwoordelijkheid, getuige onze succesvolle projecten. Heeft u een object dat aansluit bij onze criteria? Ontdek hoe wij waarde toevoegen en neem contact op met ons team voor een vruchtbare samenwerking." className="text-sm text-secondary-grey my-4" />
              <Text text="Bouw mee aan de toekomst met FR Real Estate – uw partner in kwalitatief en duurzaam vastgoed." className="text-sm text-secondary-grey" />
            </Grid>
            <Grid item xs={5} sm={3} md={2} lg={2} className="px-5 mx-auto lg:mx-0 py-10 lg:py-0">
              <img className="w-full" src="/images/new-york-portrait.png" alt="New York" />
              <img className="w-2/3 ml-auto -mr-[15%] -mt-[10%]" src="/images/quotes.svg" alt="Quotes" />
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* Slider */}
      <section id="projecten" className="py-32">
        <Container className="md:max-w-full">
          <Slider
            data={sliderData}
          />
        </Container>
      </section>

      {/* Investment opportunities */}
      <InvestmentSection />

      {/* Contact */}
      <ContactSection />
    </React.Fragment>
  )
};

export default HomePage;