const config = {
    debug: false,
    debugBorders: {
        black: 'border-4 border-black',
        green: 'border-4 border-green-400',
        orange: 'border-4 border-orange-400',
        yellow: 'border-4 border-yellow-400',
        red: 'border-4 border-red-400',
    }
};

export default config;

// Dark sections: py-16
// Light sections: py-32