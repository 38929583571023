import React from "react";

import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

import Hero from "../../components/elements/Hero";
import Link from "@mui/material/Link";

const defaultPage = () => {
  return (
    <React.Fragment>
      <section className="bg-gradient-to-b from-primary-green to-transparent pb-16 pt-40 lg:pb-40 flex">
        <Container>
          <Hero 
            title="Privacyverklaring" 
          />
            <div className="mb-4 text-center">
                Voor alle aanvullende informatie over de bescherming van persoonsgegevens kunt u terecht op de website van de Autoriteit persoonsgegevens: <Link href="https://autoriteitpersoonsgegevens.nl/nl" className="text-primary-yellow">https://autoriteitpersoonsgegevens.nl/nl</Link>.
                Met het voortzetten van het bezoek van deze website accepteert u de volgende gebruikersvoorwaarden. U accepteert het gebruik van cookies en andere tracking systems Indien u dit gebruik wenst te weigeren dient u op de volgende link te klikken <Link href="https://webbeukers.nl/nl/cookies" className="text-primary-yellow">https://webbeukers.nl/cookies</Link>.
            </div>
        </Container>
      </section>

      <section className="pb-32 flex">
        <Container>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 1 : Wettelijk</Typography>
                <p>Website: 
                    <Link href="https://webbeukers.nl/nl" className="text-primary-yellow">https://webbeukers.nl</Link> 
                </p>
                <p>Verantwoordelijke voor de verwerking van persoonsgegevens: Web Beukers, gevestigd te Anthonie Fokkerstraat 61-J, 3772MP Barneveld, kvk-nummer: 89038428.</p>   
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 2 : Toegang tot de website</Typography>
                <p>De toegang tot de website en het gebruik is strikt persoonlijk U zult deze website alsook de gegevens en informatie die daarop verstrekt worden niet gebruiken voor commerciële, politieke of publicitaire doeleinden, dan wel voor enige commerciële aanbiedingen en in het bijzonder niet gebruiken voor ongevraagde elektronische aanbiedingen.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 3 : Inhoud van de website</Typography>
                    <p>Alle merken, afbeeldingen, teksten, commentaren, illustraties, (animatie)plaatjes, videobeelden, geluiden, alsook alle technische applicaties die gebruikt kunnen worden om de website te laten functioneren en meer in het algemeen alle onderdelen die op deze site zijn gebruikt, zijn beschermd bij wet door intellectuele eigendomsrechten Iedere reproductie, herhaling, gebruik of aanpassing, op welke wijze dan ook, van het geheel of slechts een onderdeel ervan, met inbegrip van de technische applicaties, zonder voorafgaande schriftelijke toestemming van de verantwoordelijke, is ten strengste verboden Indien de beheerder niet meteen actie onderneemt tegen enig inbreuk, kan dat niet worden opgevat als stilzwijgende toestemming dan wel het afzien van rechtsvervolging.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 4 : Beheer van de website</Typography>
                <p>Voor het goede beheer van de website kan de beheerder op ieder moment:</p>
                <ol>
                    <li>De toegang aan een bepaalde categorie bezoekers schorsen, onderbreken of beperken tot de gehele of een gedeelte van de website.</li> 
                    <li>Alle informatie verwijderen die het functioneren van de website kan verstoren of in strijd is met nationale of internationale wetgeving of in strijd is met internet-etiquette.</li>
                    <li>De website tijdelijk niet beschikbaar hebben teneinde updates uit te kunnen voeren.</li>
                </ol>
                </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 5 : Verantwoordelijkheden</Typography>
                <p>De beheerder is in geen geval verantwoordelijk voor falen, storingen, moeilijkheden of onderbrekingen van het functioneren van de website, waardoor de website of een van zijn functionaliteiten niet toegankelijk is. De wijze waarop u verbinding zoekt met de website is uw eigen verantwoordelijkheid. U dient zelf alle geschikte maatregelen te treffen om uw apparatuur en uw gegevens te beschermen tegen onder andere virusaanvallen op het internet U bent bovendien zelf verantwoordelijk voor de websites en de gegevens die u op internet raadpleegt.</p>
                <p>De beheerder is niet aansprakelijk voor juridische procedures die tegen u worden gevoerd:</p>
                <ol>
                    <li> vanwege het gebruik van de website of diensten toegankelijk via internet.</li>
                    <li> vanwege het schenden van de voorwaarden van deze privacy policy. De beheerder is niet verantwoordelijk voor enige schade die u zelf oploopt, dan wel derden of uw apparatuur oplopen als gevolg van uw verbinding met of het gebruik van de website. U zult zich onthouden van iedere actie tegen de beheerder als gevolg hiervan.</li>
                </ol>
                <p>Indien de beheerder betrokken raakt bij een geschil als gevolg van uw gebruik van deze website, is hij gerechtigd alle schade die hij dientengevolge lijdt en nog zal lijden op u te verhalen.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 6 : Gegevens verzamelen</Typography>
                <p>Uw gegevens worden verzameld door Web Beukers Onder persoonsgegevens worden verstaan: alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon; als identificeerbaar wordt beschouwd een natuurlijke persoon die direct of indirect kan worden geïdentificeerd, met name aan de hand van een identificator zoals een naam, een identificatienummer, locatiegegevens, een online identificator of een of meer elementen die kenmerkend zijn voor de fysieke, fysiologische, genetische, psychische, economische, culturele of sociale identiteit.</p>
                <p>De persoonsgegevens die op de website worden verzameld worden hoofdzakelijk gebruikt door de beheerder voor het onderhouden van relaties met u en indien aan de orde voor het verwerken van uw bestellingen.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 7 : Uw rechten in verband met uw gegevens</Typography>
                <p>Op grond van artikel 13 lid 2 sub b AVG heeft een ieder recht op inzage van en rectificatie of wissing van zijn persoonsgegevens of beperking van de hem betreffende verwerking, alsmede het recht tegen de verwerking bezwaar te maken en het recht op gegevensoverdraagbaarheid U kunt deze rechten uitoefenen door contact met ons op te nemen via stephan@webbeukers.nl</p>
                <p>Ieder verzoek daartoe dient te worden vergezeld van een kopie van een geldig identiteitsbewijs, waarop u uw handtekening heeft gezet en onder vermelding van het adres waarop er met u contact kan worden opgenomen Binnen 1 maand na het ingediende verzoek, krijgt u antwoord op uw verzoek Afhankelijk van de complexiteit van de verzoeken en het aantal van de verzoeken kan deze termijn indien nodig met 2 maanden worden verlengd.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 8 : Verwerking van persoonsgegevens</Typography>
                <p>In geval van schending van enige wet- of regelgeving, waarvan de bezoeker wordt verdacht en waarvoor de autoriteiten persoonsgegevens nodig hebben die de beheerder heeft verzameld, worden deze aan hen verstrekt na een uitdrukkelijk en gemotiveerd verzoek van die autoriteiten, waarna deze persoonsgegevens mitsdien niet meer onder de bescherming van de bepalingen van deze privacyverklaring vallen.</p>
                <p>Indien bepaalde informatie noodzakelijk is om toegang te krijgen tot bepaalde functionaliteiten van de website, zal de verantwoordelijke het verplichte karakter van deze informatie aangeven op het moment van het vragen van de gegevens.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 9: Commerciële aanbiedingen</Typography>
                <p>U kunt commerciële aanbiedingen krijgen van de beheerder Indien u deze niet (meer) wenst te ontvangen, stuurt u een mail naar het volgende adres: stephan@webbeukers.nl</p>
                <p>Indien u tijdens het bezoek van de website enige persoonsgegevens tegenkomt, dient u zich te onthouden van het verzamelen ervan of van enig ander ongeoorloofd gebruik alsook van iedere daad die een inbreuk op de persoonlijke levenssfeer van die perso(o)n(en) oplevert De beheerder is in geen geval verantwoordelijk in bovengenoemde situaties.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 10 : Retentieperiode van gegevens</Typography>
                <p>De door de beheerder van website verzamelde gegevens worden gebruikt en bewaard voor de duur zoals deze bij wet is bepaald.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 11 : Cookies</Typography>
                <ol>
                    <li>A cookie is a small text file that is placed on the hard drive of your computer when you visit our website. A cookie contains data so that you can be recognized as a visitor each time you visit our website. It is then possible to tailor our website specifically to you. and to facilitate logging in. When you visit our website, a banner appears with which we inform you about the use of cookies. By continuing to use our website, you accept its use. Your consent is valid for a period of thirteen months.</li>
                    <li>We use the following types of cookies on our website: Functional cookies: such as session and login cookies to keep track of session and login information Anonymized Analytical cookies: to gain insight into the visit to our website based on information about visitor numbers, popular pages and topics In this way we can better tailor the communication and information provision to the needs of visitors to our website We cannot see who visits our websites or from which PC the visit takes place Tracking cookies: such as advertising cookies that are intended to show relevant advertisements Personal interests can be derived from the information about visited websites. This allows organizations to show their website visitors targeted advertisements, for example. Tracking cookies make it possible to draw up profiles of people and treat them differently. Tracking cookies usually process personal data. 3. More specifically, we use the following cookies: Google Analytics anonymous (analytical cookies) Hotjar Facebook (tracking cookie) Google Adwords (tracking cookie).</li>
                    <li>More specifically, we use the following cookies:  Google Analytics anonymized (analytical cookies)  Hotjar  Facebook (tracking cookie)  Google AdWords (tracking cookie). 4. When you visit our website, cookies from the controller and/or third parties may be installed on your equipment. 5. For more information on the use, management and deletion of cookies for each operating type, we invite you to consult the following link: https://autoriteitpersoonsgegevensnl/nl/onderwerpen/internet-telefoon-tv-en-post/cookies#faq.</li>
                    <li>When you visit our website, cookies from the controller and/or third parties may be installed on your equipment.</li>
                    <li>For more information on the use, management and deletion of cookies for each operating type, we invite you to consult the following link: </li>
                </ol>
                <Link href="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/internet-telefoon-tv-en-post/cookies#faq" className="text-primary-yellow">https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/internet-telefoon-tv-en-post/cookies#faq.</Link>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 12 : Afbeeldingen en producten aangeboden</Typography>
                <p>Aan het beeldmateriaal dat behoort bij de aangeboden producten op de website kunnen geen rechten worden ontleend.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 13 : Toepassing van de wet</Typography>
                <p>Op deze voorwaarden is Nederlands Recht van toepassing De rechtbank van de vestigingsplaats van de beheerder is exclusief bevoegd bij eventuele geschillen omtrent deze voorwaarden, behoudens wanneer hierop een wettelijke uitzondering van toepassing is.</p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold">Artikel 14 : Contact</Typography>
                <p>Voor vragen, productinformatie of informatie over de website zelf, kunt u zich richten tot: Stephan Striekwold, stephan@webbeukers.nl </p>
            </div>
        </Container>
      </section>     
    </React.Fragment>
  )
};

export default defaultPage;