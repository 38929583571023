import React from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import './index.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import ScrollToTop from './components/ScrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          // fontWeight: 'bold',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '50px!important',
          paddingRight: '50px!important',
        },
      },
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
        <Provider store={store}>
          <CssBaseline />
          <ScrollToTop />
          <App />
        </Provider>
    </BrowserRouter>
  </ThemeProvider>
);