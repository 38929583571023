import { AppBar, Grid, List, Stack, Link } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import React, { useEffect, useRef } from "react";
import TopbarItem from "./TopbarItem";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Routes from "../../routes/routes";

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

type Props = any;

const Topbar = (props: Props) => {
	const topbar = useRef<HTMLDivElement>(null);

	function HideOnScroll(props: Props) {
		const { children, window } = props;
		const trigger = useScrollTrigger({
			target: window ? window() : undefined,
		});
		
		return (
			<Slide appear={false} direction="down" in={!trigger} timeout={300}>
				{children}
			</Slide>
		);
	}

	// create a a click toggle func for mobile-menu-trigger
	const toggleMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
		const mobileMenu = document.getElementById('mobile-menu') as HTMLElement;
		const mobileMenuIcon = document.getElementById('mobile-menu-trigger-menu') as HTMLElement;
		const mobileMenuCloseIcon = document.getElementById('mobile-menu-trigger-close') as HTMLElement;

		// Check if menu is already open
		if (mobileMenu.classList.contains('block')) {
			mobileMenuIcon.classList.remove('hidden');
			mobileMenuCloseIcon.classList.add('hidden');

			mobileMenu.classList.remove('slideDown');
			mobileMenu.classList.add('slideUp');
		
			//set timeout to allow animation to finish
			setTimeout(() => {
				mobileMenu.classList.remove('block');
				mobileMenu.classList.add('hidden');
			}, 200);
		} else {
			mobileMenuIcon.classList.add('hidden');
			mobileMenuCloseIcon.classList.remove('hidden');

			mobileMenu.classList.remove('slideUp');
			mobileMenu.classList.add('slideDown');

			setTimeout(() => {
				mobileMenu.classList.remove('hidden');
				mobileMenu.classList.add('block');
			}, 200);
		}

	};

	useEffect(() => {
		let lastScrollTop = 0;

        const handleScroll = (e: any) => {
			const st = window.pageYOffset || document.documentElement.scrollTop;
			if (st < lastScrollTop) {
				if (window.pageYOffset > 0) {
					if (!topbar.current?.classList.contains('glass')) {
						topbar.current?.classList.add('glass');
					}
				} else {
					if (topbar.current?.classList.contains('glass')) {
						topbar.current?.classList.remove('glass');
					}
				}
			} else {
				if (topbar.current?.classList.contains('glass')) {
					topbar.current?.classList.remove('glass');
				}
			}

			lastScrollTop = st <= 0 ? 0 : st;
        };

        // window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

	return (
		<AppBar
			position="fixed"
			sx={{
				boxShadow: "unset",
				color: colorConfigs.topbar.color,
			}}
			className="h-[12.5vh] bg-transparent z-[1300]"
			ref={topbar}
		>
			<Grid container direction="row" justifyContent="flex-start" columnSpacing={0} mt={0} className="w-full h-full">
				<HideOnScroll {...props}>
					<Grid item container xs={12} className="h-full glass">
						<div className="w-full lg:w-[100vw] xl:w-[70vw]">
							{/* Menu hamburger icon */}
							<HideOnScroll {...props}>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={toggleMobileMenu}
									className="mobile-menu-trigger absolute top-5 w-16 left-0 z-10 mx-auto visible lg:invisible"
									color="inherit"
								>
									<CloseIcon id="mobile-menu-trigger-close" className="hidden mobile-menu-trigger" />
									<MenuIcon id="mobile-menu-trigger-menu" className="mobile-menu-trigger" />
								</IconButton>
							</HideOnScroll>
							
							<Grid flexGrow={1} className="h-full">
								<List component={Stack} direction="row" justifyContent="center" alignItems="center" className="gap-10 h-full">
									{Routes.map((item, index) => (
										item.showInMenu && item.showInMenu === true ?  (
											item.displayText && !item.index ?  ((
												<div key={index} className="hidden lg:block h-fit mb-6">
													<Link href={item.path} className="text-white normal-case no-underline hover:font-semibold transition-all">{item.displayText}</Link>
												</div>
											)) : null
										) : null
									))}

									<a href="/" className="h-full">
										<img className="ml-auto mr-3 lg:m-0 h-full" src="/images/logo.svg" alt="FR Real Estate" />
									</a>
								</List>
							</Grid>
						</div>
					</Grid>
				</HideOnScroll>
			</Grid>

			<HideOnScroll {...props}>
				<div id="mobile-menu" className="bg-primary-grey hidden rounded-2xl p-4 z-20 relative">
					<div>
						{Routes.map((item, index) => (
							item.showInMenu && item.showInMenu === true ?  (
								item.displayText && !item.index ? ((
									<div key={index} onClick={toggleMobileMenu}>
										<TopbarItem path={item.path}  icon="" displayText={item.displayText} isExternal={false}/>
									</div>
								)) : null
							) : null
						))}
					</div>
				</div>
			</HideOnScroll>

			<div id="left-menu-container" />
		</AppBar>
	)
};

export default Topbar;