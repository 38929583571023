import React from "react";

import useMeta from "../../components/useMeta";

const ContactPage = () => {
  return (
    useMeta({
      title: 'FR real-estate',
      description: '/',
      keywords: '',
    }),
    <div></div>
  )
};

export default ContactPage;