import React from "react";

import { Typography } from "@mui/material";

import config from "../../configs/globalConfigs";

type Props = {
    text?: string,
    texts?: Array<string>,
    font?: string,
    className?: string,
    wordBreak?: string,
};

const HeadingOne = ({ text, texts, font, className, wordBreak }: Props) => (
    text ?
        <Typography variant="h1" className={`${config.debug ? 'border-2 border-red-400' : ''} text-4xl md:text-5xl lg:text-6xl normal-case ${font ? `font-${font}` : 'font-donegol'} text-center ${className}`}>
            {text}
        </Typography>
    : texts ?
        <Typography variant="h1" className={`${config.debug ? 'border-2 border-red-400' : ''} text-4xl md:text-5xl lg:text-6xl normal-case ${font ? `font-${font}` : 'font-donegol'} text-center ${className}`}>
            {texts.map((text, index) => (
                <span key={index} className={`${font ? `font-${font}` : 'font-donegol'}`}>{text}<br className={`${wordBreak == 'mobile' ? 'block md:hidden' : 'hidden md:block'}`} /></span>
            ))}
        </Typography>
    : null
);

export default HeadingOne;