import React from 'react';

import { Typography } from '@mui/material';

const footerItems = [
    [
        {
            'className': 'text-md font-medium',
            'text': 'Contact gegevens',
        },
        {
            'className': 'text-md',
            'text': 'info@frRealestate.nl',
        },
    ],
    [
        {
            'className': 'text-md font-medium',
            'text': 'Administratieve gegevens',
        },
        {
            'className': 'text-md',
            'text': 'KVK 01058223',
        },
        {
            'className': 'text-md',
            'text': 'BTW NL0074.70.496.B01',
        },
    ],
    [
        {
            'className': 'text-sm font-light',
            'text': '© FR Real Estate ' + new Date().getFullYear(),
        },
    ]
];

const Footer = () => {
    return (
        <>
            <section className='pt-16 px-6 flex gap-14 flex-col md:flex-row justify-center relative'>
                <div className='md:w-1/2 text-center md:text-end pb-6'>
                    <Typography className='text-3xl md:text-5xl text-secondary-grey font-medium'>
                        FR Real Estate
                    </Typography>

                    {footerItems.map((item, index) => (
                        <div key={index} className='my-5'>
                            {item.map((subItem, index) => (
                                <Typography key={index} className={`footer-link text-secondary-grey leading-5 ${subItem.className}`}>
                                    {subItem.text}
                                </Typography> 
                            ))}
                        </div>
                    ))}
                </div>
                <div className='w-full md:w-1/2 max-h-full absolute md:relative left-[50%] md:left-0 -translate-x-[50%] md:translate-x-0 bottom-0' style={{ zIndex: '-1' }}>
                    <img className='relative max-h-full max-w-[85%] md:max-w-full mx-auto md:mx-0' src="/images/f-grey.svg" alt="FR Real Estate" />
                </div>
            </section>
        </>
    );
}
 
export default Footer;