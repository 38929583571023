import { RouteType } from "./config";
import React from "react";
import HomePage from "../pages/home/HomePage";
import AboutPage from "../pages/about/AboutPage";
import ContactPage from "../pages/contact/ContactPage";
import TermsAndConditions from "../pages/termsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";

import Default from "../pages/default/Default";


const Routes: RouteType[] = [
  {
    // index: true,
    path: "/",
    displayText: "Home",
    element: <HomePage />,
    state: "home",
    showInMenu: false
  },
  {
    path: "#over",
    displayText: "Over ons",
    element: <AboutPage />,
    state: "over",
    showInMenu: true
  },
  {
    path: "#projecten",
    displayText: "Projecten",
    element: <AboutPage />,
    state: "projecten",
    showInMenu: true
  },
  {
    path: "#investeringsmogelijkheden",
    displayText: "Investeringsmogelijkheden",
    element: <AboutPage />,
    state: "investeringsmogelijkheden",
    showInMenu: true
  },
  {
    path: "#contact",
    displayText: "Contact",
    element: <ContactPage />,
    state: "contact",
    showInMenu: true
  },

  {
    path: "/default",
    displayText: "Default",
    element: <Default />,
    state: "default",
    showInMenu: false
  },

  {
    path: "/algemene-voorwaarden-web-beukers",
    displayText: "Algemene voorwaarden",
    element: <TermsAndConditions />,
    state: "terms-and-conditions",
    showInMenu: false
  },
  {
    path: "/privacy-policy",
    displayText: "Privacy Beleid",
    element: <PrivacyPolicy />,
    state: "privacy-policy",
    showInMenu: false
  },
  
];

export default Routes;