import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";

import { routes } from "./routes";
import { register } from 'swiper/element/bundle';

register();

function App() {
    React.useEffect(() => {
        // set language
        document.documentElement.lang = 'nl';
    }, []);

    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                {routes}
            </Route>
        </Routes>
    );
}

export default App;
