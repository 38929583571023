import React from "react";

import { HeadingOne, Text } from "../texts";
import { Container } from "@mui/material";

const ContactSection = () => {
    return (
        <section id="contact" className="text-center py-32">
            <Container className="max-w-xl">
                <HeadingOne text="Uw vastgoedpartner" className="text-secondary-grey my-3" />
                <Text html={true} text="Bent u een makelaar of verkoper met interessant vastgoed? FR Real Estate is continu op zoek naar nieuwe mogelijkheden. Neem contact met ons op via <a href='mailto:info@frrealestate.nl'>info@frrealestate.nl</a>. Ons team van experts, geleid door Reeman Vastgoedbeheer, staat klaar om u te ondersteunen." className="text-secondary-grey" />
            </Container>
        </section>
    );
};

export default ContactSection;