import React from 'react';
import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';

type Props = {
    buttonText? : string,
    buttonLink? : string,
    className? : string,
    disabled? : boolean,
    type?: string,
    id?: string,
}

const ActionButton = ({ buttonText, buttonLink, className, disabled, type, id }: Props) => {
    return (
        <div className={`transition duration-300 custom-gradient-border-2-colors my-2 p-0.5 border-0 rounded-full hover:bg-right ${className}`}>
            {/* <Button id={id} variant="outlined" className="px-6 text-center text-white text-lg font-bold m-auto bg-primary-grey rounded-full border-none disabled:opacity-10" href={buttonLink} disabled={disabled}>
                {buttonText}
            </Button> */}

            
            <Link id={id} to={buttonLink ?? ''} className="link-element px-6 text-center text-white text-lg font-bold m-auto bg-primary-grey rounded-full border-none disabled:opacity-10 inline-flex uppercase">
                {buttonText}
            </Link>
        </div>
    );
}

export default ActionButton;