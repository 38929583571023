import React from 'react'
import { useInView, useSpring, animated } from '@react-spring/web'
import { buildInteractionObserverThreshold } from '../helpers/treshold'

// export multiple animations

type BounceProps = {
    amount?: number;
    delay?: number;
    extraclasses?: string;
    children: React.ReactNode;
}

type BounceStylesProps = {
    scale: number;
    opacity: number;
    delay: number;
}

type MoveFadeInProps = {
    amount?: number;
    delay?: number;
    direction?: string;
    distance?: string;
    extraclasses?: string;
    children: React.ReactNode;
}

type MoveFadeInStylesProps = {
    transform: string;
    opacity: number;
    delay: number;
}

export const BounceAnimation = ({ amount, delay, extraclasses, children }: BounceProps) => {
    const [bounceRef, isBounceInView] = useInView({
        rootMargin: '-5% 0px -5% 0px',
        amount: buildInteractionObserverThreshold(amount ?? 0.5),
    })
    
    const bounceStyles = useSpring({
        to: async (next: (props: BounceStylesProps) => void) => {
        await next({
            scale: isBounceInView ? 1 : 0,
            opacity: isBounceInView ? 1 : 0,
            delay: delay ?? 0,
        })
        },
        config: {
        tension: 180,
        friction: 12,
        }
    })

    return (
        // <animated.div className={Props.extraclasses ?? '' + ' animated-div'} ref={bounceRef} style={bounceStyles}>
        //     {Props.children}
        // </animated.div>

        // set opacity to 0 to hide the element when it is not in view
        <animated.div className={extraclasses ?? '' + ' animated-div'} ref={bounceRef} style={{ opacity: 0, ...bounceStyles }}>
            {children}
        </animated.div>
    );
}
    
export const MoveFadeInAnimation = ({ amount, delay, direction, distance: initDistance, extraclasses, children }: MoveFadeInProps) => {
    const [moveFadeInRef, isMoveFadeInView] = useInView({
        rootMargin: '-5% 0px -5% 0px',
        amount: buildInteractionObserverThreshold(amount ?? 0.5),
    })

    let distance = 100;
    if (distance !== undefined) {
        if (initDistance === 'xs') {
        distance = 50;
        } else if (initDistance === 'sm') {
        distance = 100;
        } else if (initDistance === 'md') {
        distance = 150;
        } else if (initDistance === 'lg') {
        distance = 200;
        } else if (initDistance === 'xl') {
        distance = 250;
        } else if (initDistance === 'xxl') {
        distance = 300;
        }
    }

    let translatePropertyFrom = `translateX(0px)`;
    let translatePropertyTo = `translateX(${distance}px)`;
    if (direction !== undefined) {
        if (direction === 'up') {
        translatePropertyFrom = `translateY(0px)`;
        translatePropertyTo = `translateY(${distance}px)`;
        } else if (direction === 'down') {
        translatePropertyFrom = `translateY(0px)`;
        translatePropertyTo = `translateY(-${distance}px)`;
        } else if (direction === 'right') {
        translatePropertyFrom = `translateX(0px)`;
        translatePropertyTo = `translateX(-${distance}px)`;
        }
    }

    const moveFadeInStyles = useSpring({
        to: async (next: (props: MoveFadeInStylesProps) => void) => {
            await next({
                transform: isMoveFadeInView ? translatePropertyFrom : translatePropertyTo,
                opacity: isMoveFadeInView ? 1 : 0,
                delay: delay ?? 0,
            })
        },
        config: {
            tension: 120,
            friction: 14,
        }
    })

    return (
        <animated.div className={extraclasses + ' animated-div' ?? 'animated-div'} ref={moveFadeInRef} style={{ opacity: 0, ...moveFadeInStyles }}>
            {children}
        </animated.div>
    );
}