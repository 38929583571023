import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type ObjectType = {
  title: string,
  description: string,
  keywords: string,
}

export default function useMeta(metaObject: ObjectType) {
  const defaultTitle = 'FR Real Estate - Expert in Duurzame Vastgoedinvesteringen';
  const defaultDescription = 'Ontdek de expertise van FR Real Estate in duurzame vastgoedinvesteringen. Van energiezuinige nieuwbouw tot strategisch gelegen kantoorgebouwen, wij combineren financieel rendement met maatschappelijke verantwoordelijkheid voor een vruchtbare samenwerking. Neem vandaag nog contact op met ons team.';
  const defaultKeywords = 'Duurzame vastgoedinvesteringen, Energiezuinige nieuwbouw, Kantoorgebouwen, Distributiecentra, Industriegronden, Winkelcentra, Maatschappelijke verantwoordelijkheid, Financieel rendement, Vastgoedprojecten, Toekomstgericht';
  
  useEffect(() => {
    document.title = metaObject.title || defaultTitle;
    document.querySelector('meta[name="title"]')!.setAttribute('content', metaObject.title || defaultTitle);
    document.querySelector('meta[name="description"]')!.setAttribute('content', metaObject.description || defaultDescription);
    document.querySelector('meta[name="keywords"]')!.setAttribute('content', metaObject.keywords || defaultKeywords);
  }, [defaultTitle, metaObject.title, defaultDescription, metaObject.description, defaultKeywords, metaObject.keywords]);

  return null;
}