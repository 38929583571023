import React, { useRef, useEffect, useState } from "react";

import { HeadingOne, HeadingTwo, Text } from "../texts";

import config from "../../configs/globalConfigs";

import { IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type Props = {
    data: Array<any>;
};

const Slider = ({ data }: Props) => {
    const [activeSlide, setActiveSlide] = useState(1);

    const slidesRef = useRef<HTMLImageElement[] | null[]>([]);
    const progressRef = useRef<HTMLDivElement>(null);
    const lineclampHeadingsRef = useRef<HTMLDivElement>(null);
    const lineclampRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        slidesRef.current = slidesRef.current.slice(0, data.length);
    }, [data]);

    const handleAnimationEnd = () => {
        if (progressRef.current !== null && progressRef.current !== undefined) {
            handleSlide('next');
        }
    };

    useEffect(() => {
        progressRef.current?.addEventListener('animationend', handleAnimationEnd);

        return () => {
            progressRef.current?.removeEventListener('animationend', handleAnimationEnd);
        }
    }, [activeSlide]);

    const handleSlide = (direction: string) => {
        let prevSlideHandled = false;
        let nextSlideHandled = false;

        prevSlideHandled = false;
        nextSlideHandled = false;

        if (direction === 'next') {
            slidesRef.current.forEach((slide: any, index: number) => {
                slide.dataset.directionTo = 'next';

                if (data.length <= 2) {
                    alert('You need at least 3 slides to use this slider.');
                } else if (data.length <= 3) {
                    if (slide.dataset.imageNr === 'active') {
                        slide.dataset.imageNr = 'prev';
                    } else if (slide.dataset.imageNr === 'next') {
                        slide.dataset.imageNr = 'active';
                        setActiveSlide(index);
                        // progressRef.current?.classList.remove('progress-animation');
                    } else if (slide.dataset.imageNr === 'prev') {
                        slide.dataset.imageNr = 'next';
                    }
                } else {
                    if (slide.dataset.imageNr === 'active') {
                        slide.dataset.imageNr = 'prev';
                    } else if (slide.dataset.imageNr === 'next' && !nextSlideHandled) {
                        slide.dataset.imageNr = 'active';
                        setActiveSlide(index);
                        // progressRef.current?.classList.add('progress-animation');
                        const newNextSlide = slidesRef.current[index + 1] ? slidesRef.current[index + 1] : slidesRef.current[0];
                        if(newNextSlide) {
                            nextSlideHandled = true;
                            newNextSlide.dataset.imageNr = 'next';
                        }
                    } else if (slide.dataset.imageNr === 'prev') {
                        slide.dataset.imageNr = '-';
                    }
                }

                if(index == data.length - 1) {
                    prevSlideHandled = false;
                    nextSlideHandled = false;
                }
            });
        } else {
            slidesRef.current.forEach((slide: any, index: number) => {
                slide.dataset.directionTo = 'prev';

                if (data.length <= 2) {
                    alert('You need at least 3 slides to use this slider.');
                } else if (data.length <= 3) {
                    if (slide.dataset.imageNr === 'active') {
                        slide.dataset.imageNr = 'next';
                    } else if (slide.dataset.imageNr === 'next') {
                        slide.dataset.imageNr = 'prev';
                    } else if (slide.dataset.imageNr === 'prev') {
                        slide.dataset.imageNr = 'active';
                        setActiveSlide(index);
                    }
                } else {
                    if (slide.dataset.imageNr === 'active') {
                        slide.dataset.imageNr = 'next';
                    } else if (slide.dataset.imageNr === 'next') {
                        slide.dataset.imageNr = '-';
                    } else if (slide.dataset.imageNr === 'prev' && !prevSlideHandled) {
                        slide.dataset.imageNr = 'active';
                        setActiveSlide(index);
                        const newPrevSlide = slidesRef.current[index - 1] ? slidesRef.current[index - 1] : slidesRef.current[data.length - 1];
                        if(newPrevSlide) {
                            prevSlideHandled = true;
                            newPrevSlide.dataset.imageNr = 'prev';
                        }
                    }
                }

                if(index == data.length - 1) {
                    prevSlideHandled = false;
                    nextSlideHandled = false;
                }
            });

            setTimeout(() => {
                slidesRef.current.forEach((slide: any, index: number) => {
                    slide.removeAttribute('data-direction-to');
                });
            }, 500);
        }
    };

    const pauseAnimation = () => {
        if (progressRef.current !== null && progressRef.current !== undefined) {
            progressRef.current.style.animationPlayState = 'paused';
        }
    };

    const playAnimation = () => {
        if (progressRef.current !== null && progressRef.current !== undefined) {
            progressRef.current.style.animationPlayState = 'running';
        }
    };

    const getSlideStatus = (index: number) => {
        return index == 0 ? 'prev' : index == 1 ? 'active' : index == 2 ? 'next' : '-'
    };

    const handleLineClamp = () => {
        if (lineclampHeadingsRef.current) {
            for (let i = 0; i < lineclampHeadingsRef.current.children.length; i++) {
                if (lineclampHeadingsRef.current.children[i].classList.contains('line-clamp-1')) {
                    lineclampHeadingsRef.current.children[i].classList.remove('line-clamp-1');
                } else {
                    lineclampHeadingsRef.current.children[i].classList.add('line-clamp-1');
                }
            }
        }

        if (lineclampRef.current?.classList.contains('line-clamp-5')) {
            lineclampRef.current?.classList.remove('line-clamp-5');
        } else {
            lineclampRef.current?.classList.add('line-clamp-5');
        }
    };

    return (
        <div className="w-full px-4 md:px-16 relative">
            <div className="relative flex">
                {data.map((slide: any, index: number) => (
                    <img key={index} ref={el => slidesRef.current[index] = el} className={`${config.debug ? (index === 0 ? config.debugBorders.red : index === 1 ? config.debugBorders.green : index === 2 ? config.debugBorders.yellow : '') : ''} w-4/5 mx-auto aspect-video object-cover absolute top-[50%] left-[50%]`} src={slide.image} data-image-nr={getSlideStatus(index)} />
                ))}

                <div className="relative w-4/5 m-auto aspect-video z-[100] group">
                    <div onClick={() => handleSlide('prev')} className="w-fit h-full absolute top-[50%] -translate-y-[50%] left-0 cursor-pointer flex">
                        <IconButton className="m-auto ml-5 transition-opacity opacity-0 group-hover:opacity-100">
                            <ArrowBackIosIcon sx={{
                                color: '#fff',
                                fontSize: '2rem',
                            }} />
                        </IconButton>
                    </div>
                    <div onClick={() => handleSlide('next')} className="w-fit h-full absolute top-[50%] -translate-y-[50%] right-0 cursor-pointer flex">
                        <IconButton className="m-auto mr-5 transition-opacity opacity-0 group-hover:opacity-100">
                            <ArrowBackIosIcon sx={{
                                color: '#fff',
                                fontSize: '2rem',
                                transform: 'rotate(180deg)',
                            }} />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div onMouseEnter={() => pauseAnimation()} onMouseLeave={() => playAnimation()} className="w-full md:w-3/4 lg:w-1/2 mx-auto -mt-[5%] lg:-mt-[15%] bg-primary-white px-8 py-10 relative z-[100]">
                {data.map((slide: any, index: number) => (
                    index === activeSlide &&
                        <div key={index} onClick={handleLineClamp}>
                            <div ref={lineclampHeadingsRef}>
                                <HeadingOne text={slide.title.text} className={`${slide.title.className} line-clamp-1`} />
                                <HeadingTwo text={slide.subtitle.text} className={`${slide.subtitle.className} line-clamp-1`} />
                            </div>
            
                            {slide.text &&
                                <div ref={lineclampRef}>
                                    <Text text={slide.text} className="text-secondary-grey mt-5 text-sm cursor-pointer line-clamp-5" />
                                </div>
                            }

                            <div ref={lineclampRef} className="cursor-pointer line-clamp-5">
                                {slide.texts && slide.texts.map((text: string, index: number) => (
                                    <Text key={index} text={text} className="text-secondary-grey mt-5 text-sm" />
                                ))}
                            </div>
            
                            <div ref={progressRef} className="h-[3px] bg-primary-yellow absolute left-0 bottom-0 progress-animation" />
                        </div>
                ))}
            </div>
        </div>
    );
};

export default Slider;