import React from "react";

import { Container } from "@mui/material";

import { HeadingOne, HeadingTwo, Text } from "../../../components/texts";

const InvestmentSection = () => {
  return (
    <section id="investeringsmogelijkheden" className="py-16 bg-primary-grey relative">
        <Container>
            <HeadingOne texts={[
                "Investerings",
                "mogelijkheden",
                " die het verschil maken",
            ]} wordBreak="mobile" />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 my-24">
                <div className="text-center">
                    <HeadingTwo text="Nieuwbouwprojecten" className="mb-5" />
                    <Text text="Ontdek de toekomst van wonen met onze nieuwste nieuwbouwprojecten. Elke ontwikkeling is ontworpen met oog voor detail, comfort en duurzaamheid, waardoor uw investering niet alleen een thuis biedt, maar ook een langdurige waardevermeerdering." />
                </div>
                <div className="text-center">
                    <HeadingTwo text="Kantoorgebouwen" className="mb-5" />
                    <Text text="Investeer in de werkplek van morgen. Onze kantoorgebouwen zijn strategisch gelegen en ontworpen om aan de dynamische behoeften van moderne bedrijven te voldoen, met flexibele werkruimtes en state-of-the-art voorzieningen." />
                </div>
                <div className="text-center">
                    <HeadingTwo texts={[
                        "Distributiecentra & ",
                        "industriegrond"
                    ]} className="mb-5" />
                    <Text text="Speel in op de groeiende vraag naar logistiek vastgoed met onze selectie van distributiecentra en industriegronden. Deze investeringen bieden strategische locaties en faciliteiten die essentieel zijn voor de efficiëntie van de supply chain." />
                </div>
                <div className="text-center">
                    <HeadingTwo texts={[
                        "Supermarkten & ",
                        "Winkelcentra"
                    ]} className="mb-5" />
                    <Text text="Zet in op de veerkracht van retail met onze supermarkten en winkelcentra. Deze locaties trekken een constant klantenbestand aan en bieden een mix van essentiële en lifestyle winkels, waardoor ze een stabiele inkomstenbron vormen in diverse economische klimaten." />
                </div>
            </div>
        </Container>

        <img className="absolute bottom-0 left-[50%] -translate-x-[50%] max-h-full" src="/images/key.svg" alt="" />
    </section>
  )
};

export default InvestmentSection;