import React from "react";

import useMeta from "../../components/useMeta";

const AboutPage = () => {
  return (
    useMeta({
      title: 'FR Real Estate',
      description: '/',
      keywords: '',
    }),
    <div></div>
  )
};

export default AboutPage;