import React from "react";

import { Typography } from "@mui/material";

import config from "../../configs/globalConfigs";

type Props = {
    text?: string,
    texts?: Array<string>,
    font?: string,
    className?: string,
    wordBreak?: string,
};

const HeadingTwo = ({ text, texts, font = 'helvetica', className, wordBreak }: Props) => (
    text ?
        <Typography className={`${config.debug ? 'border-2 border-orange-400' : ''} text-2xl md:text-3xl lg:text-4xl font-medium ${font ? `font-${font}` : ''} ${className}`}>
            {text}
        </Typography>
    : texts ?
        <Typography className={`${config.debug ? 'border-2 border-orange-400' : ''}`}>
            {texts.map((text, index) => (
                <span key={index} className={`text-2xl md:text-3xl lg:text-4xl font-medium ${font ? `font-${font}` : ''} ${className}`}>{text}<br className={`${wordBreak == 'mobile' ? 'block md:hidden' : 'hidden md:block'}`} /></span>
            ))}
        </Typography>
    : null
);

export default HeadingTwo;