import React from "react";

import useMeta from "../../components/useMeta";

const Default = () => {
  return (
    useMeta({
      title: 'FR real-estate',
      description: '/',
      keywords: '',
    }),
    <div>
    </div>
  )
};

export default Default;