import React from "react";

import { Typography } from "@mui/material";

const HeroSection = () => {
    return (
        <section className={`h-[70vh] md:h-[50vh] lg:h-[70vh] bg-primary-grey pt-[15vh] md:pt-0 relative z-[1200] flex flex-col md:flex-row`}>
            <div className="flex-grow max-h-[50%] md:max-h-full h-full flex flex-col pb-10 md:pb-0 justify-center items-center">
                <div className="relative z-[2]">
                    <Typography variant="h1" className="text-5xl lg:text-7xl normal-case font-donegol text-center md:text-end">
                        {'"Een plek om '}
                        <br className="hidden md:block" />
                        {'thuis te '}
                        <br className="hidden md:block" />
                        {'noemen"'}
                    </Typography>
                    <Typography variant="h5" className="text-1xl lg:text-2xl normal-case text-center md:text-end italic">
                        {'~ FR Real Estate'}
                    </Typography>
                </div>
            </div>
            <img className="max-w-[75vw] md:max-w-[50vw] lg:max-w-[50vw] max-h-[50%] md:max-h-full h-full object-cover object-left ml-auto relative z-[14000]" src="/images/new-york-with-f.png" alt="" />
        </section>
    );
};

export default HeroSection;